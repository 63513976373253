$body-bg: #efefef;
$input-bg: #fff;
$table-bg: #fff;

@import '~bootstrap';

body{
    -webkit-font-smoothing: antialiased;
    font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    color: #3d4852;
}
.app-table{
    width: 100%;
    background-color: #fff;
}

.app-table th{
    border-bottom: 1px solid #efefef;
    //padding: 18px
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
}

.app-table td{
    border-bottom: 1px solid #efefef;
    //padding: 18px
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
}

.app-table thead tr{
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #efefef;
}

.page-nav a{
    //padding-left: 12px;
    //padding-right: 12px;
    //padding-top: 8px;
    //padding-bottom: 8px;
    //text-decoration: none;
    //color: #3d4852;
}

.page-nav a.active{
    border-bottom: 3px solid #c7dbd2;
    //font-weight: bold;
}

.page-nav {
    //margin-top: 12px;
    //border-bottom: 1px solid #c7dbd2;
    //padding-bottom: 6px;
}

.panel {
    border-bottom: 1px solid #c7dbd2;
    margin-bottom: 0px;
    background-color: #fff;
}

div.field_with_errors {
    display: inline;
    input, textarea, select{
        @extend .is-invalid;
    }
}

div.seen_by{
    display: none !important;
}

.sidebar-font-style{
    font-size: 14px;
    color: #1c1f23
}

.sidebar-active{
    border-left: 5px skyblue solid;
}

@media print {
    .navbar {
        display: none;
    }

    #edit_button{
        display: none;
    }

    body{
        background-color: #ffffff;
    }

    .print_view{
        border: none !important;
        box-shadow: none !important;
    }

    .service_log_header{
        display: none !important;
    }

    div.seen_by{
        display: inline !important;
    }
}

.w-60 {
    width: 60% !important;
}

.w-40 {
    width: 40% !important;
}


.table > :not(caption) > * > * {

}

.table > :not(caption) > * > * {
    background-color: white !important;
}
